import { config } from '../config';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './oidcConfig';
import type { PropsWithChildren } from 'react';
import { AutoLogin } from './AutoLogin';
import { RestoreInitialRoute } from './RestoreInitialRoute';

export const RioAuthProvider = ({ children }: PropsWithChildren) =>
    config.login.mockAuthorization ? (
        children
    ) : (
        <AuthProvider {...oidcConfig}>
            <RestoreInitialRoute />
            <AutoLogin>{children}</AutoLogin>
        </AuthProvider>
    );
