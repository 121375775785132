import { routeStorage } from '../configuration/login/storage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trace } from '../configuration/setup/trace';

/**
 * Restores the initial route after the user has been logged in.
 */
export const RestoreInitialRoute = () => {
    const [initialRoute, setInitialRoute] = useState(routeStorage.getRoute());

    const navigate = useNavigate();

    useEffect(() => {
        trace('RestoreInitialRoute: Checking for initial route', initialRoute);
        if (initialRoute !== null) {
            trace('RestoreInitialRoute: Restoring initial route', initialRoute);
            routeStorage.discardRoute();
            setInitialRoute(null);
            const [path, queryString] = initialRoute.split('?');
            trace(`Navigating to path '${path}' with qs '${queryString}'`);
            navigate({
                pathname: `/${path}`,
                search: `?${queryString}`,
            });
        }
    }, [initialRoute, navigate]);

    return null;
};

// Previous solution was to use location.replace(). However, this triggers a reload of the entire page, which in turn
// causes another call to the OIDC provider. To avoid this use the navigate hook from react-router-dom.
